import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import boys1 from "../../assets/seller1.jpg"; // Replace with actual image paths
import boys2 from "../../assets/seller2.jpg"; // Replace with actual image paths
import boys3 from "../../assets/seller3.jpg"; // Replace with actual image paths
import girls1 from "../../assets/059A2397 copy.jpg"; // Replace with actual image paths
import girls2 from "../../assets/059A2422 copy.jpg"; // Replace with actual image paths
import girls3 from "../../assets/1.jpg"; // Replace with actual image paths
import { useNavigate } from 'react-router-dom';

const categories = [
    // { id: 1, name: 'Boys' },
    { id: 2, name: 'Girls' },
];

const boysCategories = [
    { id: 1, name: 'Kurta', img: boys1 },
    { id: 2, name: 'Shirt', img: boys2 },
    { id: 3, name: 'Pants', img: boys3 },
];

const girlsCategories = [
    { id: 1, name: 'Lehnga-choli', img: girls1 },
    { id: 2, name: 'Dress', img: girls2 },
    { id: 3, name: 'Clothing-set', img: girls3 },
];

const Sellers = () => {
    const [activeTab, setActiveTab] = useState('Girls');

    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const renderCategories = (categories) => (
        <div className="row d-none d-md-flex flex-wrap justify-content-center">
            {categories.map((category) => (
                <div key={category.id} className="col-12 col-sm-6 col-md-4 mb-4 px-0 px-sm-1">
                    <div className="position-relative">
                        <img src={category.img} alt={category.name} className="img-fluid" />
                        <div
                            className="position-absolute text-center py-2 selerbtn"
                            onClick={() => navigate('ourcollection/669ab4035641fc4867db9d91')}
                            style={{ 
                                bottom: '15px', 
                                left: '10px', 
                                right: '10px',
                                width: 'calc(100% - 20px)',
                                fontWeight: '700',
                                height: '50px',
                                lineHeight:'30px'
                            }}
                        >
                            {category.name}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderSliderCategories = (categories) => (
        <Slider {...settings}
        autoplay={true}
          autoplaySpeed={3000}
        >
            {categories.map((category) => (
                <div key={category.id} className="mb-0 mb-lg-4 px-0 px-sm-1">
                    <div className="position-relative">
                        <img src={category.img} alt={category.name} className="img-fluid" />
                        <div 
                            className="position-absolute text-center bg-white py-2 selerbtn" 
                            style={{ 
                                bottom: '15px', 
                                left: '10px', 
                                right: '10px',
                                width: 'calc(100% - 20px)',
                                cursor: 'pointer',
                                height: '50px',
                                lineHeight:'30px'
                            }}
                        >
                            {category.name}
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );

    return (
        <section className='sellers'>
            <div className="container mx-auto">
                <div className="text-center title mb-8">
                    <h4 className='font-semibold sellerstitle mb-2 title'>Best Sellers</h4>
                </div>
                <div className="mb-1 mt-3">
                    <nav className="bg-white">
                        <ul className="sellersmenu navbar-nav flex-row d-flex justify-content-center">
                            {categories.map((category) => (
                                <li className="flex-shrink-0" key={category.id}>
                                    <a 
                                        href="javascript:void(0);" 
                                        className={`sellersitem nav-link d-flex justify-content-center align-items-center rounded-full hover:bg-gray-300 ${activeTab === category.name ? 'active' : ''}`}
                                        onClick={() => setActiveTab(category.name)}
                                    >
                                        {category.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                <div className="container mt-2 mt-lg-4">
                    {/* {activeTab === 'Boys' && (
                        <>
                            {renderCategories(boysCategories)}
                            <div className="d-block d-md-none">
                                {renderSliderCategories(boysCategories)}
                            </div>
                        </>
                    )} */}
                    {activeTab === 'Girls' && (
                        <>
                            {renderCategories(girlsCategories)}
                            <div className="d-block d-md-none">
                                {renderSliderCategories(girlsCategories)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Sellers;
